import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import parse from "html-react-parser"

const PrivacyPolicy = ({ data, location }) => {
  return (
    <Layout>
      <Seo title={"Privacy Policy"} location={location} />
      <div className="cha-privacy-policy__header"></div>
      <div className="cha-privacy-policy">
        <h1>{data.privacyPolicy.headline}</h1>
        <p>{parse(data.privacyPolicy.content)}</p>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy

export const query = graphql`
  query PrivacyPolicyQuery {
    privacyPolicy {
      headline
      content
    }
  }
`
